import mapService from "../../../business/mapService";
import { MAP_TILES } from "./constants";
import L from 'leaflet';

export default {
    name: 'MapToolsComponent',
    components: {
    },
    props: {
        map: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            mapTiles: MAP_TILES,
            showLayerPicker: false,
            layers: {},
            weatherLayers: [],
            currentLayer: null
        }
    },
    mounted() {
        const { currentLayer } = mapService.initLayers(this.mapTiles, this.layers, this.map);
        this.currentLayer = currentLayer;
    },
    methods: {
        changeTile(reloadWeatherLayers = true) {
            if (this.map && this.layers[this.currentLayer]) {
                this.map.eachLayer(layer => {
                    if (layer instanceof L.TileLayer) this.map.removeLayer(layer)
                })
                this.layers[this.currentLayer].addTo(this.map)
                this.showLayerPicker = false

                if (reloadWeatherLayers) {
                    this.weatherLayers.forEach(layer => {
                        if (layer.selected) {
                            layer.layer.addTo(this.map)
                        }
                    });
                }
            }
        }
    }
}