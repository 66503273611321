import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"toolbar"},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"icon-button mx-2",attrs:{"dark":"","color":"icon-primary"},on:{"click":function($event){return _vm.map.zoomIn()}}},on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("map.zoomIn")))])]),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"icon-button mx-2",attrs:{"dark":"","color":"icon-primary"},on:{"click":function($event){return _vm.map.zoomOut()}}},on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("remove")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("map.zoomOut")))])])],1),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"mx-2 icon-button layers-btn",attrs:{"dark":"","color":"icon-primary"},on:{"click":function($event){_vm.showHideList = false;
          _vm.showWeatherList = false;
          _vm.showLayerPicker = !_vm.showLayerPicker;}}},on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("layers")])],1),_c(VCard,{directives:[{name:"show",rawName:"v-show",value:(_vm.showLayerPicker),expression:"showLayerPicker"}],staticClass:"mx-auto layer-picker",attrs:{"max-width":"400"}},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"mandatory":"","color":"primary"},on:{"change":function($event){return _vm.changeTile()}},model:{value:(_vm.currentLayer),callback:function ($$v) {_vm.currentLayer=$$v},expression:"currentLayer"}},_vm._l((_vm.mapTiles),function(layer){return _c(VListItem,{key:layer.name,attrs:{"value":layer.name}},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(layer.icon ? layer.icon : 'map')}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(layer.translateName ? _vm.$t(layer.name) : layer.name)}})],1)],1)}),1)],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("map.map")))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }