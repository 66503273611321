import selectorService from '@/business/selectorService';
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue';
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue';
import MapToolsComponent from '@/components/map/mapTools/MapToolsComponent.vue';
import leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';
import filterService from '../../business/filter.service';
import mapService from '../../business/mapService';
import thingService from '../../business/thingService';
import { goFullscreenById } from '../../tools/functions';
import { DEFAULT_REFRESH_DATA, DEFAULT_STOREROOM_RADIUS, FILTERS } from './constants';

export default {
    name: 'MapBaseComponent',
    components: {
        SelectorComponent,
        AutocompleteComponent,
        MapToolsComponent
    },
    props: {},
    data() {
        return {
            mapId: 'mapBaseComponent',
            mapInstance: null,
            loadingSpinner: false,
            markersMap: {},
            updateInterval: null,
            drawer: false,
            dialogCluster: false,
            thingItems: [],
            thingSelected: null,
            markersGroup: null,
            configBase: {
                idElement: 'mapBaseComponent',
                centerPosition: [-24.475390108274823, -65.03700482239184],
                zoom: 8,
                preferCanvas: false,
                zoomControl: true,
                renderer: leaflet.canvas()
            },
            selectorModel: selectorService.getSelectorModel(false),
            thingsFilter: FILTERS.THINGS,
            sectorFilter: FILTERS.SECTOR,
            activityFilter: FILTERS.ACTIVITIES,
            thingTypeFilter: FILTERS.TYPES,
            stateFilter: FILTERS.STATES,
            categoryFilter: FILTERS.CATEGORIES,
            userThings: null,
            lastUpdateTimestamp: null,
            defaultRadius: DEFAULT_STOREROOM_RADIUS,
            radiusFilter: {
                show: false, label: "mapHistory.selector.radius.label", id: "storeRoomRadius", value: DEFAULT_STOREROOM_RADIUS, title: "mapHistory.selector.radius.title"
            },
            showZones: false,
            zonesMakerMap: new Map(),
            pointsOfInterestMap: new Map(),
            filters: [],
            markerClusterSize: 40,
            showLabels: false,
            showHidePointsOfInterest: false,
            zoomClusterMarkers: false,
            fullscreenIcon: 'fullscreen',
            isFullScreen: false
        }
    },
    beforeDestroy() {
    },
    async mounted() {
        this.loadingSpinner = true;
        try {
            await mapService.getUpdatedIcons();
            this.mapInstance = mapService.createMap(this.configBase);
            await this.initFilterSelector();
            this.userThings = await thingService.getAllUserThingsByEnterprise();
            const tracks = await mapService.getPositionsByThings(this.userThings.map(thing => thing._id));
            this.thingItems = this.userThings.map((thingSelected) => { return { name: thingSelected.name, id: thingSelected._id } });
            const { markersMap, markersGroup } = await mapService.createMarkerByTracks(this.mapInstance, tracks, this.defaultRadius, this.showLabels);
            this.markersMap = markersMap;
            this.markersGroup = markersGroup;
            this.lastUpdateTimestamp = moment().utc().unix();
            this.startInterval();
        } finally {
            this.loadingSpinner = false;
        }
    },
    onDestroy() {
        clearInterval(this.updateInterval);
    },
    computed: {},
    methods: {
        async startInterval() {
            this.updateInterval = setInterval(async () => {
                const tracks = await mapService.getLastCurrentStateData(this.userThings.map(thing => thing._id), this.lastUpdateTimestamp);
                this.lastUpdateTimestamp = moment().utc().unix();
                mapService.updateMarkersByTracks(tracks, this.markersMap, this.markersGroup, this.mapInstance, this.radiusFilter.value, this.filters, this.showLabels);
            }, DEFAULT_REFRESH_DATA);
        },
        async applyFilters() {
            this.loadingSpinner = true;
            clearInterval(this.updateInterval);
            try {
                const filters = [];
                const poinZoneFilters = [];
                const thingsMap = new Map();
                const stateMap = new Map();
                const sectorMap = new Map();
                const activityMap = new Map();
                const typeMap = new Map();
                const categoryMap = new Map();

                if (this.thingsFilter.selectedData && this.thingsFilter.selectedData.length > 0) {
                    filterService.getThingsMapSelectedById(this.thingsFilter.selectedData, thingsMap);
                    filters.push({ field: "thingId", conditions: thingsMap });
                }

                if (this.stateFilter.selectedData && this.stateFilter.selectedData.length > 0) {
                    filterService.getSelectorMapById(this.stateFilter.selectedData, stateMap);
                    filters.push({ field: "stateKey", conditions: stateMap, nulable: false });
                }

                if (this.sectorFilter.selectedData && this.sectorFilter.selectedData.length > 0) {
                    filterService.getSelectorMapById(this.sectorFilter.selectedData, sectorMap);
                    filters.push({ field: "sectorId", conditions: sectorMap });
                }

                if (this.activityFilter.selectedData && this.activityFilter.selectedData.length > 0) {
                    filterService.getSelectorMapById(this.activityFilter.selectedData, activityMap);
                    filters.push({ field: "activityId", conditions: activityMap, nulable: false });
                }

                if (this.thingTypeFilter.selectedData && this.thingTypeFilter.selectedData.length > 0) {
                    filterService.getSelectorMapById(this.thingTypeFilter.selectedData, typeMap);
                    filters.push({ field: "typeKey", conditions: typeMap });
                }

                if (this.categoryFilter.selectedData && this.categoryFilter.selectedData.length > 0) {
                    filterService.getSelectorMapById(this.categoryFilter.selectedData, categoryMap);
                    poinZoneFilters.push({ field: "category", conditions: categoryMap });
                }
                this.filters = filters;
                mapService.filterMarkersByTrackData(filters, this.mapInstance, this.markersGroup, this.markersMap);
                mapService.filterPointOfInterestAndZones(this.mapInstance, Array.from(this.pointsOfInterestMap.values()), Array.from(this.zonesMakerMap.values()), poinZoneFilters);
                mapService.updateCircleRadius(Array.from(this.markersMap.values()), this.radiusFilter.value);
            } finally {
                this.startInterval();
                this.loadingSpinner = false;
            }
        },
        async initFilterSelector() {
            this.selectorModel.showTimeFormatSelector = false;
            this.selectorModel.showDateAndTimeSelector = false;
            this.selectorModel.hideAutoReport = true;
            this.selectorModel.generateButtonLabel = "map.apply";
            this.selectorModel.disableGetReportButton = false;

            this.selectorModel.numericInputs.cleanAndUpdate([this.radiusFilter]);
            const [
                thingsByGroup,
                sectors,
                activities,
                types,
                states,
                categories
            ] = await Promise.all([
                filterService.getThingsByGroup(),
                filterService.getSectorToSelector(),
                filterService.getActivitiesToSelector(),
                filterService.getAllThingTypesToSelector(),
                filterService.getAllStatesToSelector(),
                filterService.getAllCategoriesByEnterprise()
            ])

            this.categoryFilter.data.cleanAndUpdate(categories);
            this.thingsFilter.data.cleanAndUpdate(thingsByGroup);
            this.sectorFilter.data.cleanAndUpdate(sectors);
            this.activityFilter.data.cleanAndUpdate(activities);
            this.thingTypeFilter.data.cleanAndUpdate(types);
            this.stateFilter.data.cleanAndUpdate(states);

            this.selectorModel.filters.push(
                this.thingsFilter, this.sectorFilter, this.activityFilter, this.thingTypeFilter,
                this.stateFilter, this.categoryFilter
            );
        },
        centerMap(event) {
            if (event && event.id) {
                const marker = this.markersMap.get(event.id);
                mapService.centerMapByMarker(marker, 20, this.mapInstance);
            }
        },
        async showHideZones() {
            this.loadingSpinner = true;
            try {
                this.showZones = !this.showZones;
                await mapService.showHideZonesByEnteprise(this.showZones, this.mapInstance, this.zonesMakerMap);
            } finally {
                this.loadingSpinner = false;
            }
        },
        showEditClusterDialog() {
            this.dialogCluster = true;
        },
        saveEditCluster() {
            this.loadingSpinner = true;
            clearInterval(this.updateInterval);
            try {
                this.markersGroup = mapService.updateMaxClusterRadius(this.mapInstance, Array.from(this.markersMap.values()), this.markersGroup, this.markerClusterSize);
            } finally {
                this.loadingSpinner = false;
                this.dialogCluster = false;
                this.startInterval();
            }
        },
        async showTooltipAction() {
            this.loadingSpinner = true;
            clearInterval(this.updateInterval);
            try {
                this.showLabels = !this.showLabels;
                await mapService.updateIconsOfMarkers(Array.from(this.markersMap.values()), this.showLabels);
            } finally {
                this.loadingSpinner = false;
                this.startInterval();

            }
        },
        async showPointsOfInterest() {
            this.showHidePointsOfInterest = !this.showHidePointsOfInterest;
            await mapService.showHidePointOfInterest(this.mapInstance, this.showHidePointsOfInterest, this.pointsOfInterestMap);
        },
        fullScreen() {
            this.isFullScreen = !this.isFullScreen;
            this.fullscreenIcon = this.isFullScreen ? "fullscreen_exit" : "fullscreen";
            goFullscreenById(this.mapId + "element");
        }
    }
}
